import {
  AuthProvider,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  UserCredential,
} from 'firebase/auth';
import { doc, DocumentReference, setDoc } from 'firebase/firestore';
import { authState } from 'rxfire/auth';
import { docData } from 'rxfire/firestore';
import { BehaviorSubject, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { User } from '../interfaces/user';
import { ConfigService } from './config.service';
import { UserService } from './user.service';

export class AuthService {
  private static instance: AuthService;

  user$: BehaviorSubject<User> = new BehaviorSubject(null);

  private constructor() {
    authState(ConfigService.getInstance().getConfig().auth)
      .pipe(
        switchMap(user => {
          if (user) {
            const firestore = ConfigService.getInstance().getConfig().firestore;
            return docData<User>(doc(firestore, `users/${user.uid}`) as DocumentReference<User>);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(user => {
        const currentUser = this.user$.getValue();
        if (currentUser && currentUser.impersonating && currentUser.impersonator) {
          // the update was actually for the original user (impersonator)
          this.copyProperties(currentUser.impersonator, user);
          this.user$.next({ ...currentUser });
        } else {
          this.user$.next(user);
        }
      });
  }

  static getInstance() {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  login(email: string, password: string): Promise<UserCredential> {
    const auth = ConfigService.getInstance().getConfig().auth;
    return signInWithEmailAndPassword(auth, email, password);
  }

  register(user: User, password: string) {
    const auth = ConfigService.getInstance().getConfig().auth;

    return createUserWithEmailAndPassword(auth, user.email, password)
      .then(credentials => {
        if (credentials) {
          updateProfile(credentials.user, { displayName: user.username }).then(() =>
            this.createUserDocument(credentials.user.uid, user),
          );
        }
      })
      .catch(error => {
        console.error(error);
        // TODO: if (error.message) this.notifier.notify('error', error.message);
      });
  }

  createUserDocument(uid: string, user: User): Promise<void> {
    const firestore = ConfigService.getInstance().getConfig().firestore;
    const docRef = doc(firestore, 'users', uid) as DocumentReference<User>;
    const data = {
      uid,
      firstname: user.firstname,
      lastname: user.lastname,
      username: user.username,
      email: user.email,
      admin: false,
      createdAt: new Date(),
      projects: 0,
      projectLimit: 3,
    };

    return setDoc(docRef, data);
  }

  signOut() {
    const currentUser = this.user$.getValue();

    if (!currentUser) return;

    if (currentUser.impersonating && currentUser.impersonator) {
      this.copyProperties(currentUser, currentUser.impersonator);
      currentUser.impersonating = false;
      delete currentUser.impersonator;
      this.user$.next(currentUser);
      return;
    }

    ConfigService.getInstance().getConfig().auth.signOut();
  }

  facebookSignIn(): Promise<void> {
    const provider = new FacebookAuthProvider();
    provider.addScope('email'); // should not be needed
    return this.socialSignIn(provider);
  }

  private socialSignIn(provider: AuthProvider): Promise<void> {
    const auth = ConfigService.getInstance().getConfig().auth;
    return signInWithPopup(auth, provider).then(credential => this.updateUserData(credential));
  }

  private updateUserData(credential: UserCredential): Promise<void> {
    const firebaseAuthUser = ConfigService.getInstance().getConfig().auth.currentUser;

    return UserService.getInstance()
      .get(firebaseAuthUser.uid)
      .pipe(first())
      .toPromise() // FIXME
      .then(currentUser => {
        currentUser.uid = currentUser.uid || firebaseAuthUser.uid;

        if (!currentUser) {
          currentUser = {
            uid: firebaseAuthUser.uid,
            email: firebaseAuthUser.email || firebaseAuthUser?.providerData[0]?.email,
            username: credential?.user?.displayName || `user_${firebaseAuthUser.uid}`,
            admin: false,
            createdAt: new Date(),
            projects: 0,
          };
        }

        currentUser.email = currentUser.email || firebaseAuthUser.email || firebaseAuthUser?.providerData[0]?.email;
        currentUser.username = currentUser.username || credential?.user?.displayName || `user_${firebaseAuthUser.uid}`;

        const profile = {}; // FIXME: was (credential && credential.additionalUserInfo && credential.additionalUserInfo.profile) || {};
        currentUser.photoURL = currentUser.photoURL || AuthService.extractPhotoUrl(profile);
        currentUser.firstname = currentUser.firstname || (profile && profile['first_name']);
        currentUser.lastname = currentUser.lastname || (profile && profile['last_name']);

        return UserService.getInstance().update(currentUser);
      });
  }

  private static extractPhotoUrl(profile): string {
    return profile && profile.picture && profile.picture.data && profile.picture.data.url;
  }

  canImpersonate(target: User): boolean {
    const loggedInUser = this.user$.getValue();
    if (!loggedInUser || !loggedInUser.uid || !target || !target.uid) return false;
    if (!loggedInUser.admin) return false;
    if (loggedInUser.uid === target.uid) return false;
    return true;
  }

  impersonate(other: User) {
    const currentUser = this.user$.getValue();
    if (!this.canImpersonate(other)) return;
    currentUser.impersonator = JSON.parse(JSON.stringify(currentUser));
    currentUser.impersonating = true;
    this.copyProperties(currentUser, other);
    this.user$.next(currentUser);
  }

  private copyProperties(to: User, from: User) {
    to.uid = from.uid;
    to.photoURL = from.photoURL;
    to.firstname = from.firstname;
    to.lastname = from.lastname;
    to.username = from.username;
    to.email = from.email;
    to.admin = from.admin;
    to.createdAt = from.createdAt;
    to.projects = from.projects;
  }
}
