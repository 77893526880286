import {
  collection,
  doc,
  DocumentReference,
  limit,
  orderBy,
  Query,
  query,
  QueryConstraint,
  setDoc,
  startAfter,
  where,
} from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { collectionData, docData } from 'rxfire/firestore';
import { uploadBytesResumable } from 'rxfire/storage';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { User } from '../interfaces/user';
import { ConfigService } from './config.service';

export class UserService {
  private constructor() {}

  private static instance: UserService;

  static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  get(uid: string): Observable<User> {
    const firestore = ConfigService.getInstance().getConfig().firestore;
    const docRef = doc(firestore, 'users', uid) as DocumentReference<User>;
    return docData<User>(docRef);
  }

  findByUsername(username: string): Observable<User> {
    const firestore = ConfigService.getInstance().getConfig().firestore;
    const users = collection(firestore, 'users');
    const q = query(users, where('username', '==', username)) as Query<User>;
    return collectionData<User>(q, { idField: 'uid' }).pipe(map(u => (u?.length > 0 ? u[0] : null)));
  }

  getAll(lim: number = 20, after?: User): Observable<User[]> {
    const firestore = ConfigService.getInstance().getConfig().firestore;
    const users = collection(firestore, 'users');
    const constraints: QueryConstraint[] = [orderBy('projects', 'desc'), limit(lim)];
    if (after) {
      constraints.push(startAfter(after));
    }
    const q = query(users, ...constraints);
    return collectionData(q, { idField: 'id' }) as Observable<User[]>;
  }

  uploadImage(uid: string, file: File): Observable<number> {
    const ext = file.name.split('.').pop();
    const storage = ConfigService.getInstance().getConfig().storage;
    const r = ref(storage, `profiles/${uid}.${ext}`);
    return uploadBytesResumable(r, file, { contentType: file.type }).pipe(
      map(snapshot => {
        // map to progress 0 - 100
        return (100.0 * snapshot.bytesTransferred) / snapshot.totalBytes;
      }),
      finalize(() => {
        getDownloadURL(r).then(url => this.setPhotoUrl(uid, url));
      }),
    );
  }

  private setPhotoUrl(uid: string, imageUrl: string): Promise<void> {
    const firestore = ConfigService.getInstance().getConfig().firestore;
    const docRef = doc(firestore, 'users', uid) as DocumentReference<User>;
    return setDoc(docRef, { photoURL: imageUrl }, { merge: true });
  }

  update(user: User): Promise<void> {
    if (!user || !user.uid) {
      console.error('Failed to update user.', user);
      return;
    }

    // remove anything that's undefined
    Object.keys(user).forEach(key => user[key] === undefined && delete user[key]);

    // to be safe in case of update during impersonation
    const clone = JSON.parse(JSON.stringify(user));
    delete clone['impersonating'];
    delete clone['impersonator'];

    const firestore = ConfigService.getInstance().getConfig().firestore;
    const docRef = doc(firestore, 'users', user.uid) as DocumentReference<User>;
    return setDoc(docRef, clone, { merge: true }).catch(e => console.error(e));
  }
}
